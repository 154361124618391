import React from "react"
import Layout from "../components/layout"
import Title from "../components/title"
import Cta from "../components/cta"
import { Link, useStaticQuery, graphql } from "gatsby"

import "../styles/object/component/card.scss"
import "../styles/object/component/table.scss"
import "../styles/object/component/typography.scss"
import "../styles/object/project/recruit.scss"
import define from "../config/define";

const query = graphql`
  query {
    engineer: file(relativePath: { eq: "recruit_engineer.svg" }) {
      publicURL
    }
    designer: file(relativePath: { eq: "recruit_designer.svg" }) {
      publicURL
    }
  }
`

export default () => {
  const data = useStaticQuery(query)

  return (
    <Layout title="Recruit">
      <Title top="RECRUIT" topSub="採用情報" />
      <main className="l-main">
        <section className="position">
          <div className="c-heading">
            <h4 className="c-heading_main">POSITION</h4>
            <h5 className="c-heading_sub">職種</h5>
          </div>
          <div className="container">
            <div className="positionContainer">
              <img
                src={data.engineer.publicURL}
                alt="エンジニアのイメージベクター画像"
                className="positionContainer_image--engineer"
                width="300px"
                height="227px"
              />
              <div className="positionContainer_heading">エンジニア</div>
              <div className="c-card">
                <dl className="positionList">
                  <dt className="positionList_heading">一緒に働きたい人物像</dt>
                  <dd className="positionList_content">
                    ・チームの成功を喜べる方
                  </dd>
                  <dd className="positionList_content">
                    ・課題を整理し行動できる方
                  </dd>
                  <dd className="positionList_content">
                    ・古いもの／新しいものが好きな方
                  </dd>
                  <dd className="positionList_content">
                    ・より良いものを共有できる方
                  </dd>
                  <dd className="positionList_content">
                    ・プログラムが好きな方
                  </dd>
                </dl>
                <dl className="positionList">
                  <dt className="positionList_heading">歓迎スキル</dt>
                  <dd className="positionList_content">
                    ・HTML5 / CSS3 / JavaScript開発経験者
                  </dd>
                  <dd className="positionList_content">・React.js / Vue.js</dd>
                  <dd className="positionList_content">・Github 経験者</dd>
                </dl>
              </div>
            </div>
            <div className="positionContainer">
              <img
                src={data.designer.publicURL}
                alt="デザイナーのイメージベクター画像"
                className="positionContainer_image--designer"
                width="300px"
                height="186px"
              />
              <div className="positionContainer_heading">webデザイナー</div>
              <div className="c-card">
                <dl className="positionList">
                  <dt className="positionList_heading">一緒に働きたい人物像</dt>
                  <dd className="positionList_content">・クリエイティブな方</dd>
                  <dd className="positionList_content">・デザインが好きな方</dd>
                </dl>
                <dl className="positionList">
                  <dt className="positionList_heading">歓迎スキル</dt>
                  <dd className="positionList_content">
                    ・Illustrator / Photoshopのデザイン経験
                  </dd>
                  <dd className="positionList_content">
                    ・HTML5 / CSS3のマークアップ経験
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </section>
        <section className="jobDescription">
          <div className="c-heading">
            <h4 className="c-heading_main">JOB DESCRIPTION</h4>
            <h5 className="c-heading_sub">募集要項</h5>
          </div>
          <div className="container">
            <div className="c-card">
              <table>
                <tbody>
                  <tr>
                    <th>仕事内容</th>
                    <td>
                      <div>WEBフロントエンド開発</div>
                      <div>VR・ARシステム開発</div>
                      <div>ドローンシステム開発</div>
                    </td>
                  </tr>
                  <tr>
                    <th>勤務地</th>
                    <td>
                      本社（ {define.corp.addr} ）及び取引先
                    </td>
                  </tr>
                  <tr>
                    <th>勤務時間</th>
                    <td>
                      <div>09:00 ～ 17:30（1日の標準労働時間7.5時）</div>
                      <div>※取引先によって異なります</div>
                    </td>
                  </tr>
                  <tr>
                    <th>雇用形態</th>
                    <td>正社員(3カ月の試用期間有り)</td>
                  </tr>
                  <tr>
                    <th>給与</th>
                    <td>
                      <div>
                        待遇・給与
                        経験・年齢・能力を考慮の上当社規定により優遇いたします。
                      </div>
                      <div>※400～800万程度</div>
                    </td>
                  </tr>
                  <tr>
                    <th>休日・休暇</th>
                    <td>
                      <div>完全週休2日制(土・日)</div>
                      <div>祝日</div>
                      <div>夏季休暇</div>
                      <div>年末年始休暇(12/31 ～ 01/03)</div>
                      <div>有給休暇</div>
                      <div>慶弔休暇等</div>
                    </td>
                  </tr>
                  <tr>
                    <th>賞与</th>
                    <td>年2回（夏季・冬季）</td>
                  </tr>
                  <tr>
                    <th>待遇・福利厚生</th>
                    <td>各種社会保険、交通費支給</td>
                  </tr>
                  <tr>
                    <th>採用の流れ</th>
                    <td>
                      <div>
                        <Link to={`/contact/`} className="c-linkBorder">
                          お問い合わせフォームよりご連絡ください。
                        </Link>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </main>
      <Cta />
    </Layout>
  )
}
